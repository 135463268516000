import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
  box-sizing: border-box;
  word-wrap: break-word;
}

img {
    border: 0;
    height: auto;
    max-width: 100%;
}

html {
  font-family: 'Poppins', sans-serif;
  color: rgba(0, 0, 0, 0.75);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #78d92e;
}

audio,
canvas,
progress,
video {
  display: inline-block; // 1
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: #00ff00;
  &:active {
    outline: 0;
  }
  &:hover {
    outline: 0;
    color: #5cc73b;
  }
}

input {
  height: 3.5rem;
  width: 100%;
  border-radius: 2px;
  padding: .5rem;
  border: 1px solid rgb(82, 95, 84, 0.5);

}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

p {
  line-height: 1.85rem;
  margin-top: 0;
}

ul, ol {
  margin: 0;
  padding-left: 2rem;
  list-style-type: none;
  @media (min-width: 480px) {
    padding-left: 3.5rem;
  }
}

ul {
    li {
    line-height: 2rem;
    font-size: 1.1rem;
    list-style: none;
    margin-bottom: 0;
    position: relative;
    &:after {
      background: #00ff00;
      border-radius: 16px;
      content: '';
      height: 3px;
      left: -2rem;
      position: absolute;
      top: 1rem;
      width: 10px;
    }
    @media (min-width: 480px) {
      &:after {
        left: -3.5rem;
      }
    }
  }
}

ol {
  li {
    counter-increment: step-counter;
    margin-bottom: 0.5rem;
    position: relative;

    &:before {
      background-color: #00ff00;
      border-radius: 15px;
      box-shadow: 0 0 0px 3px #00ff00;
      color: #fff;
      content: counter(step-counter);
      font-size: 0.8rem;
      font-weight: bold;
      height: 15px;
      left: -2rem;
      line-height: 1.3rem;
      position: absolute;
      text-align: center;
      top: 4px;
      width: 15px;
    }
    @media (min-width: 480px) {
      &:before {
        width: 20px;
        height: 20px;
        left: -3.5rem;
      }
    }
  }
}

h1 {
  font-size: 3.3rem;
  font-weight: 300;
  line-height: 4rem;
  margin-bottom: 4rem;
  margin-top: 0;
}

h2 {
  position: relative;
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 3rem;
  margin-bottom: 4rem;
  margin-top: 0;
  &::after {
    background: #00ff00;
    bottom: -1.5rem;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 2rem;
  }
}

h3 {
  position: relative;
  font-size: 1.7rem;
  font-weight: 300;
  line-height: 2.6rem;
  margin-bottom: 2.6rem;
  margin-top: 0;
  &::after {
    background: #00ff00;
    bottom: -1.3rem;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 1rem;
  }
}

h4 {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2.4rem;
  margin-bottom: 2.4rem;
  margin-top: 0;
}

h5 {
  font-size: 1rem;
  font-weight: bold;
  line-height: 2rem;
  margin-bottom: 2rem;
  margin-top: 0;
}

h6 {
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 1rem;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"], // 1
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; // 2
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  // cursor: default;
  cursor: not-allowed;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; // 1
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield; // 1
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0; // 1
  padding: 0;
}


textarea {
  overflow: auto;
}


optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

td,
th {
  padding: 0;
}

.rc-table {
  .rc-table-row {
    td {
      &:first-child {
        width: 55px;
      }
      &:nth-child(2) {
        width: 80px;
      }
      &:nth-child(4) {
        width: 24px;
        padding-right: 32px;
      }
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .rc-table-expanded-row {
    td {
      white-space: normal;
      font-size: 16px;
      padding: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
`;
