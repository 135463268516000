const createMenu = (intl, pages) => {
  // initial fix menu entries
  const menu = [
    {
      label: intl.formatMessage({ id: 'home.workingTogether' }),
      url: `/clients`,
    },
    { label: intl.formatMessage({ id: 'home.workingAt' }), url: `/jobs` },
  ];
  // add landing pages
  pages.forEach(page => {
    menu.push({
      label: page.title,
      url: `/${page.slug}`,
    });
  });

  // menu.push({
  //   label: intl.formatMessage({ id: 'home.blog' }),
  //   url: `https://blog.euri.com`,
  // });
  return menu;
};

export default createMenu;
