import React from 'react';
import PropTypes from 'prop-types';
// import Link from 'gatsby-link';
import { injectIntl } from 'react-intl';

import Link from './link';
import { getLocalizedPath } from '../core/i18n';
import { socialsShape } from '../core/propTypes';
import createMenu from '../services/createMenu';
import LinkIcon from './linkIcon';

import EuricomLogo from '../assets/images/euricom-logo-white-new.svg';
import EuricomLogoMobile from '../assets/images/euricom-logo-white-mobile-new.svg';

import StyledNav from '../style/menu/styledNav';
import StyledMenu from '../style/menu/styledMenu';
import StyledLogoIcon from '../style/menu/styledLogoIcon';
import StyledLogoIconMobile from '../style/menu/styledLogoIconMobile';
import StyledMenuOverlayMobile from '../style/menu/styledMenuOverlayMobile';
import StyledBurgerMenu from '../style/menu/styledBurgerMenu';
import StyledMenuItems from '../style/menu/styledMenuItems';
import StyledMenuItemsMobile from '../style/menu/styledMenuItemsMobile';
import StyledRightHandSide from '../style/menu/styledRightHandSide';
import StyledButton from '../style/menu/styledButton';
import StyledSocials from '../style/menu/styledSocials';
import StyledSocialsMobile from '../style/menu/styledSocialsMobile';
import StyledCloseButton from '../style/menu/styledCloseButton';
import StyledTransparentOverlay from '../style/menu/styledTransparentOverlay';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOverlay: false,
    };
  }

  componentDidMount() {
    // document object needs to be accessed after the component
    // has mounted or it won't be found when building
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState(
      {
        body: document.getElementsByTagName('BODY')[0],
      },
      () => {
        this.state.body.setAttribute('style', 'overflow: auto'); // needed for safari browser
      },
    );
  }

  toggleMenu = () => {
    this.setState(
      prevState => ({
        activeOverlay: !prevState.activeOverlay,
      }),
      () => {
        this.state.body.setAttribute('style', `overflow: ${this.state.activeOverlay ? 'hidden' : 'auto'}`);
      },
    );
  };

  render() {
    const { socials, fullMenu, backLabel, backSlug, intl, pages, activeHeadline } = this.props;
    const menu = createMenu(intl, pages);
    const menuItems = menu.map(item => (
      <Link to={getLocalizedPath(item.url, intl.locale)} key={item.label}>
        {item.label}
      </Link>
    ));

    return (
      <StyledNav>
        <StyledMenu>
          <Link to={getLocalizedPath('', intl.locale)}>
            <StyledLogoIcon>
              <EuricomLogo />
            </StyledLogoIcon>

            <StyledLogoIconMobile>
              <EuricomLogoMobile />
            </StyledLogoIconMobile>
          </Link>

          <StyledMenuItems fullMenu={fullMenu}>{menuItems}</StyledMenuItems>
        </StyledMenu>

        <StyledTransparentOverlay activeOverlay={this.state.activeOverlay} onClick={() => this.toggleMenu()} />

        <StyledMenuOverlayMobile
          onClick={() => this.toggleMenu()}
          className={this.state.activeOverlay ? 'toggled' : ''}
        >
          <StyledCloseButton>
            <div />
          </StyledCloseButton>
          <StyledMenuItemsMobile
            activeHeadline={activeHeadline}
            activeOverlay={this.state.activeOverlay}
            onClick={() => this.toggleMenu()}
          >
            {menuItems}
          </StyledMenuItemsMobile>
          <StyledSocialsMobile>
            {socials.map(social => (
              <div key={social.profileType}>
                <LinkIcon url={social.url} svg={social.icon.inlineSvg} />
              </div>
            ))}
          </StyledSocialsMobile>
        </StyledMenuOverlayMobile>

        <StyledBurgerMenu onClick={() => this.toggleMenu()} fullMenu={fullMenu}>
          <em />
        </StyledBurgerMenu>

        <StyledRightHandSide fullMenu={fullMenu}>
          <StyledButton href={getLocalizedPath(backSlug || '', intl.locale)}>
            <span>
              <em />
            </span>
            {backLabel}
          </StyledButton>

          <StyledSocials>
            {socials.map(social => (
              <div key={social.profileType}>
                <LinkIcon url={social.url} svg={social.icon.inlineSvg} />
              </div>
            ))}
          </StyledSocials>
        </StyledRightHandSide>
      </StyledNav>
    );
  }
}

Menu.propTypes = {
  socials: socialsShape,
  fullMenu: PropTypes.bool,
  backLabel: PropTypes.string,
  backSlug: PropTypes.string,
  activeHeadline: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
};

Menu.defaultProps = {
  socials: [],
  pages: [],
  fullMenu: false,
  backLabel: 'Back',
  backSlug: '',
  activeHeadline: false,
};

export default injectIntl(Menu);
