import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';

import { HelmetDatoCms } from 'gatsby-source-datocms';

import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import nl from 'react-intl/locale-data/nl';
import 'intl';

import 'typeface-permanent-marker'; // eslint-disable-line import/extensions
import 'typeface-shadows-into-light'; // eslint-disable-line import/extensions
import 'typeface-poppins'; // eslint-disable-line import/extensions

import { getCurrentLangKey } from '../core/i18n';
import i18n from '../data/i18n';
import GlobalStyle from '../style/injectGobalStyle';

addLocaleData([...nl, ...en]);

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        globalInfo: datoCmsGlobalInfo {
          address
          copyright
          logo {
            sizes(maxWidth: 250, imgixParams: { fm: "png" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
        site: datoCmsSite {
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />
        <Location>
          {({ location }) => {
            const url = location.pathname;
            const { site } = data;
            const locale = getCurrentLangKey(i18n.langs, i18n.defaultLangKey, url);
            return (
              <IntlProvider locale={locale} messages={i18n.messages[locale]}>
                <div>
                  <HelmetDatoCms favicon={site.faviconMetaTags} />
                  {children}
                </div>
              </IntlProvider>
            );
          }}
        </Location>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Layout;
