import styled from 'styled-components';

const StyledSocials = styled.div`
  display: flex;
  div {
    cursor: pointer;
    display: flex;
    margin-left: 6px;

    &:first-child {
      margin-left: 2rem;
    }
    a {
      width: 40px;
      height: 40px;

      border: 2px solid #fff;
      border-radius: 50px;
      background: rgba(#00ff00, 0);

      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 18px;
        width: 18px;
        fill: #fff;
      }
      &:hover {
        transition: all 0.3s ease-out;
        background: #00ff00;
        border: 2px solid #00ff00;
        svg {
          fill: #fff;
        }
      }
    }
  }
`;

export default StyledSocials;
