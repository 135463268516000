import styled from 'styled-components';

const StyledLogoIconMobile = styled.div`
  svg {
    @media (min-width: 480px) {
      display: none;
    }
  }
`;

export default StyledLogoIconMobile;
