import styled from 'styled-components';

const StyledMenuOverlayMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: fixed;
  z-index: 10;
  right: -40vw;
  top: 0;

  width: 40vw;
  height: 100vh;

  background-color: rgb(243, 243, 243);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

  overflow: hidden;
  transition: all 0.3s ease-out;

  &.toggled {
    right: 0;
  }

  @media (max-width: 480px) {
    width: 100vw;
    right: -100vw;
  }
`;

export default StyledMenuOverlayMobile;
