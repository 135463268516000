import enMessages from './messages/en';
import nlMessages from './messages/nl';

const messages = {
  messages: {
    nl: nlMessages,
    en: enMessages,
  },
  langs: ['en', 'nl'],
  defaultLangKey: 'nl',
};

export default messages;

// module.exports = {
//   messages: {
//     nl: nlMessages,
//     en: enMessages,
//   },
//   langs: ['en', 'nl'],
//   defaultLangKey: 'nl',
// };
