import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const LinkUniversal = ({ to, children, ...props }) => {
  // It is a simple element with nothing to link to
  if (!to) return <span {...props}>{children}</span>;

  // It is intended to be an external link
  if (/^https?:\/\//.test(to))
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );

  // Finally, it is an internal link
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

LinkUniversal.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default LinkUniversal;
