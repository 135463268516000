module.exports = {
  back: 'Terug',
  'home.workingTogether': 'Samenwerken met',
  'home.workingAt': 'Werken bij',
  'home.blog': 'Blog',
  share: 'delen',
  'jobs.who': 'wie zoeken we?',
  send: 'verstuur',
  'send.placeholder': 'laat uw email achter',
  'contact.submit.success': 'Je bericht werd correct verzonden, we contacteren je zo snel mogelijk!',
  'contact.submit.failed': 'Er liep iets mis. Je kan ons alsnog contacteren via careers@euri.com',
  '404.button': 'terug naar home',
  '404.description': 'Pagina niet gevonden',
  'E.title': 'Hi, mijn naam is E.',
  'E.description': 'De me3ste software developers versla 1k met m1nder dan 10% van mijn CPU.',
  'E.description.2': 'Of ben jij een gr3nsverlegger?',
  'E.code.1': 'K0men we h1er snel te w3ten',
  'E.code.2': '“Of durf je niet?”',
  'E.footer.lhs.1': 'H3b je mij al verslag3n?',
  'E.footer.lhs.2': 'L33r hier m33r over de bedrijfscultuur van Euricom.',
  'E.footer.rhs.action': 'werken bij →',
  'bootcamp.footer.lhs.1': 'Wil je direct aan de slag?',
  'bootcamp.footer.lhs.2': 'Leer hier meer over de bedrijfscultuur van Euricom.',
  'bootcamp.introduction.1':
    'Ben jij klaar om de kneepjes van de JavaScript wereld te leren kennen, of wil je jouw bestaande carrière een boost geven?',
  'bootcamp.introduction.2': 'Zoek dan niet verder want via ons ',
  'bootcamp.introduction.3': 'Full Stack JavaScript bootcamp ',
  'bootcamp.introduction.4':
    'zal je jouw strepen verdienen, achteraf kan je onze rangen aansterken en werken we samen om onze missies tot succes te brengen. 💪',
  'bootcamp.cta.1': 'KLIK HIER',
  'bootcamp.cta.2': 'VOOR MEER',
};
