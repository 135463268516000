import styled from 'styled-components';

const StyledRightHandSide = styled.div`
  display: none;
  align-items: center;
  z-index: 1;
  @media (min-width: 1024px) {
    display: ${props => (props.fullMenu ? 'flex' : 'none')};
  }
`;

export default StyledRightHandSide;
