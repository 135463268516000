import styled from 'styled-components';

const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  a {
    cursor: pointer;
    z-index: 10;
  }
`;

export default StyledMenu;
