import styled from 'styled-components';

const StyledMenuItemsMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  a {
    display: ${props => (props.activeOverlay ? 'block' : 'none')};
    width: calc(100% - 2rem);

    color: #1d2129;
    user-select: none;

    padding: 1rem;
    margin-bottom: 0.75rem;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: #fff;

    &:active {
      background-color: rgb(237, 237, 237);
      transition: background-color 0.2s;
    }
  }
`;

export default StyledMenuItemsMobile;
