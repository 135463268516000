import styled from 'styled-components';

const StyledButton = styled.a`
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  border: 2px solid #5cc73b;
  border-radius: 100px;
  background: #5cc73b;
  padding: 10px 23px;
  &:hover {
    color: #fff;
  }
  span {
    transition: all 0.2s ease-out;
    margin-left: 0px;
    em {
      transition: opacity 0s linear;
      opacity: 0;
      &:before {
        transition: all 0.2s 0.15s ease-out;
        transform: rotate(0deg);
        transform-origin: 0 50%;
        content: '';
        position: absolute;
        width: 0.3rem;
        height: 2px;
        border-radius: 2px;
        background: white;
        top: 21px;
        left: 20px;
      }
      &:after {
        transition: all 0.2s 0.15s ease-out;
        transform: rotate(0deg);
        transform-origin: 0 50%;
        content: '';
        position: absolute;
        width: 0.3rem;
        height: 2px;
        background: white;
        border-radius: 2px;
        top: 21px;
        left: 20px;
      }
    }
  }
  &:hover {
    span {
      margin-left: 16px;
      em {
        opacity: 1;
        &:before {
          transform: rotate(-45deg);
          content: '';
          position: absolute;
          width: 0.6rem;
        }
        &:after {
          transform: rotate(45deg);
          content: '';
          position: absolute;
          width: 0.6rem;
        }
      }
    }
  }
`;

export default StyledButton;
