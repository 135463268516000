import styled from 'styled-components';

const StyledTransparentOverlay = styled.div`
  display: ${props => (props.activeOverlay ? 'block' : 'none')};
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;

  width: 60vw;
  height: 100vh;

  @media (max-width: 480px) {
    display: none;
  }
`;

export default StyledTransparentOverlay;
