import styled from 'styled-components';

const StyledCloseButton = styled.div`
  width: 100%;
  padding: ${props => (props.noPadding ? '0' : '2rem 1rem')};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  div {
    width: 32px;
    height: 32px;
    opacity: 0.3;
    text-align: center;
    &:active {
      opacity: 1;
    }
    &:before,
    &:after {
      position: absolute;
      content: '';
      height: 33px;
      width: 2px;
      background-color: #687178;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
`;

export default StyledCloseButton;
