import styled from 'styled-components';

const StyledSocialsMobile = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  padding: 2rem 1rem;
  margin-top: auto;
  div {
    cursor: pointer;
    display: flex;
    &:nth-child(2) {
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }
    a {
      width: 40px;
      height: 40px;

      border: 2px solid #00ff00;
      border-radius: 50px;
      background: #00ff00;

      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 18px;
        width: 18px;
        fill: #fff;
      }
    }
  }
`;

export default StyledSocialsMobile;
