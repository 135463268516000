import styled from 'styled-components';

const StyledLogoIcon = styled.div`
  svg {
    display: none;
    @media (min-width: 480px) {
      display: block;
    }
  }
`;

export default StyledLogoIcon;
