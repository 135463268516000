module.exports = {
  back: 'Back',
  'home.workingTogether': 'Working together',
  'home.workingAt': 'Working with',
  'home.blog': 'Blog',
  share: 'share',
  'jobs.who': 'who are we looking for?',
  send: 'send',
  'send.placeholder': 'drop your email here',
  'contact.submit.success': `Your message has been sent, we'll contact your as soon as possible!`,
  'contact.submit.failed': 'Something went wrong. You can still contact us via careers@euri.com',
  '404.button': 'back to home',
  '404.description': 'Page not found',
  'E.title': 'Hi, my name is E.',
  'E.description': 'I b3at most software develop3rs with less than 10% of my CPU.',
  'E.description.2': 'Or are you a pion33r?',
  'E.code.1': 'We will find out s00n',
  'E.code.2': '“Or are you afraid?”',
  'E.footer.lhs.1': 'Did you alr3ady b3at me?',
  'E.footer.lhs.2': "L3arn more about Euricom's corporate culture h3re.",
  'E.footer.rhs.action': 'working with →',
};
