import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: ${props => props.color || '#5cc73b'};
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.15 ease;
  transform: translateY(0px);
  &:hover {
    box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.25), 0 6px 16px 0 rgba(150, 150, 150, 0.15);
    color: ${props => (props.color ? '#5cc73b' : '#fff')};
    transform: translateY(-1px);
  }
  &:focus {
    outline: 0;
  }
  display: inline-block;
  vertical-align: top;
  margin: ${props => (props.noMargin ? '0' : '0 1rem 1rem 0')};
  padding: ${props => (props.smallPadding ? '.5rem 1.5rem' : '1.25rem 2.75rem')};
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-size: ${props => (props.large ? '1rem' : '0.75rem')};
  font-weight: 600;
  letter-spacing: 1px;
  color: ${props => (props.color ? '#5cc73b' : '#fff')};
  text-align: center;
  border-radius: ${props => (props.rectangle ? '2px' : '100px')};
  border: 0;
  cursor: pointer;
  height: ${props => props.height};
`;

export default StyledButton;
