import styled from 'styled-components';

const StyledMenuItems = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: ${props => (props.fullMenu ? 'flex' : 'none')};
    flex-wrap: wrap;

    margin-left: 2rem;
    width: 100%;
    a {
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;

      margin-left: 1rem;
      padding-bottom: 3px;

      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: transparent;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        border-bottom-color: #00ff00;
        color: #fff;
        transition: border-bottom 0.2s;
      }
    }
  }
`;

export default StyledMenuItems;
