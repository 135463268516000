import { keyframes } from 'styled-components';

export const transformItem = keyframes`
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
`;

export const fadeItem = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    }
`;
