import React from 'react';
import PropTypes from 'prop-types';

const LinkIcon = props => {
  const { url, svg, onClick } = props;
  return (
    <a
      onClick={onClick}
      href={url}
      target={url === '#' ? '' : '_blank'}
      rel="noopener noreferrer"
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

LinkIcon.propTypes = {
  url: PropTypes.string,
  svg: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

LinkIcon.defaultProps = {
  url: '#',
  onClick: () => '',
};

export default LinkIcon;
