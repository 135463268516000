import PropTypes from 'prop-types';

export const imageShape = PropTypes.shape({
  sizes: PropTypes.object,
});

export const richTextShape = PropTypes.shape({
  md: PropTypes.shape({
    html: PropTypes.string,
  }),
});

export const socialsShape = PropTypes.arrayOf(
  PropTypes.shape({
    profileType: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      inlineSvg: PropTypes.string.isRequired,
    }).isRequired,
  }),
);
