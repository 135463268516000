import styled from 'styled-components';

const StyledBurgerMenu = styled.span`
  @media (max-width: 1023px) {
    position: relative;
    z-index: 1;
    border-radius: 50%;
    cursor: pointer;
    display: ${props => (props.fullMenu ? 'inline-block' : 'none')};
    height: 46px;
    transition: border-color 0.8s ease;
    transition-delay: 0.15s;
    vertical-align: middle;
    width: 46px;
    outline: none;
    position: relative;
    &:before {
      left: 50%;
      top: 6px;
      transform: translate(-50%, 0);
      content: '';
      display: block;
      background: #fff;
      width: 40px;
      height: 3px;
      position: absolute;
      transition: all 0.5s ease;
      opacity: 1;
      border-radius: 1px;
    }
    &:after {
      left: 50%;
      bottom: 6px;
      transform: translate(-50%, 0);
      content: '';
      display: block;
      background: #fff;
      width: 40px;
      height: 3px;
      position: absolute;
      transition: all 0.5s ease;
      opacity: 1;
      border-radius: 1px;
    }
    em {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      &:before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        content: '';
        display: block;
        background: #fff;
        width: 40px;
        height: 3px;
        position: absolute;
        transition: all 0.2s ease;
        transition-delay: 0.15s;
        border-radius: 1px;
      }
      &:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);

        content: '';
        display: block;
        background: #fff;
        width: 40px;
        height: 3px;
        position: absolute;
        transition: all 0.2s ease;
        transition-delay: 0.15s;
        border-radius: 1px;
      }
    }
  }
`;

export default StyledBurgerMenu;
